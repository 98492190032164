import React, { useState, useEffect, useContext } from "react";
import AbpAppService from "common/AbpService";
import { AuthContext } from "contexts/AuthContext";

export function useNumberCallData(params) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const { state } = useContext(AuthContext);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const result = await NumberCallData();
      setData(result);
      setLoading(false);
    }

    loadData();
  }, [params.from, params.to, params.kiosks]);

  async function NumberCallData() {
    const service = new AbpAppService("dashboard");

    const result = await service.invoke(
      "get",
      "GetNumberCall",
      params,
      state.token
    );
    return result.data.result;
  }

  return {
    data,
    loading
  };
}
