export const getDefaultHeaders = () => ({
    'Accept': 'application/json',
    'Content-Type': 'application/json'
});

export const getResponseExceptionMessage = (ex) => {

    if (ex.response && ex.response.data && ex.response.data.error){
        return ex.response.data.error.message;
    }
    else {
        return ex.message;
    }
};