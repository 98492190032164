import React, { useState, useEffect, useContext } from "react";
import { Copyright } from "App";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Input } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import { FormLabel } from "@material-ui/core";
import { mainListItems, secondaryListItems } from "./listItems";
import AverageDurationCallChart from "chart/AverageDurationCallChart";
import NumberCallChart from "chart/NumberCallChart";
import NumberCallByIntervalChart from "chart/NumberCallByIntervalChart";
import moment from "moment";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { useKioskData } from "api/KioskData";
import Title from "common/Title";
import { AuthContext } from "contexts/AuthContext";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {
    height: 240
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  labelTextDateTimePicker: {
    fontSize: 12
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [kioskSelect, setKioskSelect] = React.useState([]);

  const handleChangeKiosk = event => {
    setKioskSelect(event.target.value);
  };

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  const [start, setStart] = useState(moment().startOf("month"));
  const [end, setEnd] = useState(moment().endOf("month"));

  const kioskList = useKioskData();

  const { state, dispatch } = useContext(AuthContext);

  let local = {
    format: "DD/MM/YYYY HH:mm",
    sundayFirst: false
  };

  let ranges = {
    "Aujourd'hui": [moment().startOf("day"), moment().endOf("day")],
    Hier: [
      moment()
        .subtract(1, "days")
        .startOf("day"),
      moment()
        .subtract(1, "days")
        .endOf("day")
    ],
    "3 derniers jours": [
      moment()
        .subtract(3, "days")
        .startOf("day"),
      moment().endOf("day")
    ]
  };
  let maxDate = moment(end).add(24, "hour");
  let disabled = false;
  let value = `${moment(start).format("DD/MM/YYYY HH:mm")} - ${moment(
    end
  ).format("DD/MM/YYYY HH:mm")}`;

  function dateTimePickerApplyCallback(pStart, pEnd) {
    setStart(moment(pStart));
    setEnd(moment(pEnd));
  }

  function dateTimePickerRangesCallback(index, value) {
    setStart(moment(ranges[value][0]));
    setEnd(moment(ranges[value][1]));
  }

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  function logout() {
    dispatch({
      type: "LOGOUT"
    });
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard, Bienvenue {state.user && state.user.name}
          </Typography>
          <IconButton color="inherit" onClick={logout}>
            <Badge color="secondary">
              <ExitToAppIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
        {/* <Divider />
        <List>{secondaryListItems}</List> */}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ExpansionPanel
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Title>Filtres</Title>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <FormLabel
                          ref={inputLabel}
                          className={classes.labelTextDateTimePicker}
                        >
                          Date
                        </FormLabel>
                        <DateTimeRangeContainer
                          ranges={ranges}
                          start={start}
                          end={end}
                          local={local}
                          maxDate={maxDate}
                          noMobileMode={true}
                          applyCallback={dateTimePickerApplyCallback}
                          rangeCallback={dateTimePickerRangesCallback}
                          smartMode
                        >
                          <Input
                            type="text"
                            style={{ cursor: "pointer" }}
                            disabled={disabled}
                            value={value}
                            fullWidth={true}
                          />
                        </DateTimeRangeContainer>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel
                          ref={inputLabel}
                          htmlFor="multiple-kiosk-select"
                        >
                          Borne
                        </InputLabel>
                        <Select
                          labelId="multiple-kiosk-select-label"
                          id="multiple-kiosk-select"
                          multiple
                          value={kioskSelect}
                          onChange={handleChangeKiosk}
                          input={<Input />}
                          MenuProps={MenuProps}
                        >
                          <option value="" />
                          {!kioskList.loading &&
                            kioskList.data &&
                            kioskList.data.length > 0 &&
                            kioskList.data.map(item => {
                              return (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name} ({item.id})
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            {/* Chart */}
            <Grid item xs={3}>
              <Paper className={fixedHeightPaper}>
                <AverageDurationCallChart
                  startDate={start}
                  endDate={end}
                  kiosks={kioskSelect}
                />
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={fixedHeightPaper}>
                <NumberCallChart
                  startDate={start}
                  endDate={end}
                  kiosks={kioskSelect}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper
                className={fixedHeightPaper}
                style={{ overflow: "visible" }}
              >
                <NumberCallByIntervalChart
                  startDate={start}
                  endDate={end}
                  kiosks={kioskSelect}
                />
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
