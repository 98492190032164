import React, { useState, useContext, useEffect } from "react";

import AbpAppService from "common/AbpService";
import { AuthContext } from "contexts/AuthContext";

export function useAuthentication() {
  const service = new AbpAppService("tokenauth", "/api/");
  const sessionService = new AbpAppService("session");

  const [userId, setUserId] = useState();

  let { state, dispatch } = React.useContext(AuthContext);

  useEffect(() => {
    async function load() {
      let token = localStorage.getItem("token");

      if (token) {
        dispatch({
          type: "LOGIN",
          payload: { token: token }
        });

        await loadSession(token);
      }
    }

    load();
  }, []);

  async function loadSession(authToken) {
    var response = await sessionService.invoke(
      "get",
      "GetCurrentLoginInformations",
      {},
      authToken
    );

    if (response.data.result && response.data.result.user) {
      dispatch({
        type: "SESSION_LOADED",
        payload: { user: response.data.result.user }
      });
    }
    else {
      dispatch({
        type: "FAILURE"
      });
    }
  }

  async function authenticate(login, password, tenancyName, remember) {
    let response = null;
    const data = {
      userNameOrEmailAddress: login,
      password: password,
      tenancyName: tenancyName,
      rememberClient: remember
    };
    try {
      response = await service.invoke("post", "Authenticate", data, null);
    } catch (ex) {
      dispatch({
        type: "FAILURE"
      });
      return;
    }
    let authToken = response.data.result.accessToken;
    dispatch({
      type: "LOGIN",
      payload: { token: authToken }
    });

    await loadSession(authToken);
  }

  return {
    authenticate
  };
}
