import React, { useState, useContext } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import ProTip from "./ProTip";
import Dashboard from "./dashboard/Dashboard";
import SignIn from "./signin/SignIn";
import { AuthContext, AuthContextProvider } from "./contexts/AuthContext";

export function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.instore-solution.fr/">
        InStore Solution
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function App() {
  let { state, dispatch } = useContext(AuthContext);

  return <div>{state.isAuthenticated ? <Dashboard /> : <SignIn />}</div>;
}
