import axios from 'axios';
import { getDefaultHeaders } from 'common/apiHelpers';
import qs from 'qs';

const defaultServicePath = '/api/services/app/';
export const apiBaseUrl = window.odigo.apiBaseUrl;

export default class AbpAppService{

    serviceName = null;
    servicePath = null;

    constructor(serviceName, servicePath){
        this.serviceName = serviceName;
        this.servicePath = servicePath || defaultServicePath;
    }

    invoke(verb, methodName, input, authToken){
        
        let params = null;
        let bearerToken = null;

        if (input){
            if (typeof input === 'object'){
                params = input;
            }
            else {
                bearerToken = input;
            }
        }

        if (!bearerToken && authToken){
            bearerToken = authToken;
        }

        const headers = {
            ...getDefaultHeaders()
        };

        if (bearerToken){
            headers.Authorization = `Bearer ${bearerToken}`;
        }
        if (verb === 'get' || verb === 'delete') {
            
            return axios[verb](`${apiBaseUrl}${this.servicePath}${this.serviceName}/${methodName}`, {
                headers: headers,
                params: params,
                paramsSerializer: params => {
                  return qs.stringify(params)
                }
            });
        }
        else {
            return axios[verb](`${apiBaseUrl}${this.servicePath}${this.serviceName}/${methodName}`, params, {
                headers: headers
            });
        }
    }

    getAll = (input, authToken) => {
    
        return this.invoke('get', 'getAll', input, authToken);
    
    };
    
    get = (input, authToken) => {
    
        return this.invoke('get', 'get', input, authToken);
    
    };
    
    create = (input, authToken) => {
    
        return this.invoke('post', 'create', input, authToken);
    
    };
    
    update = (input, authToken) => {
    
        return this.invoke('put', 'update', input, authToken);
    
    };
    
    delete = (input, authToken) => {
    
        return this.invoke('delete', 'delete', input, authToken);
    
    };

}