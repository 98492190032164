import React from "react";

const AuthContext = React.createContext();

const initialState = {
  isAuthenticated: false,
  loading: false,
  user: null,
  token: null,
  success: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("token", action.payload.token);

      return {
        ...state,
        token: action.payload.token,
        loading: true,
        success: true
      };
    case "SESSION_LOADED":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        loading: false
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        success: null
      };
    case "FAILURE":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        loading: false,
        success: false
      };
    case "FAILURE_DISMISS":
      return {
        ...state,
        success: null
      };
    default:
      return state;
  }
};

function AuthContextProvider(props) {
  // [A]
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };

  // [B]
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}

// [C]
export { AuthContext, AuthContextProvider };
