import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext
} from "react";
import { Copyright } from "App";
import { useAuthentication } from "../hooks/useAuthentication";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { css } from "@emotion/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { AuthContext } from "contexts/AuthContext";
import { CircleLoader } from "react-spinners";
import { useTheme } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "common/SnackbarContentWrapper";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  screenCentered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    height: "100%"
  }
}));

export default function SignIn() {
  const classes = useStyles();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [remember, setRemember] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const theme = useTheme();

  let { state, dispatch } = React.useContext(AuthContext);

  const authentication = useAuthentication();

  useEffect(() => {
    if (state.success == false) setOpenSnackbar(true);
  }, [state.success]);

  function sendRequestEnter(ev) {
    if (ev.key === "Enter") {
      authentication.authenticate(email, password, "default", remember);
    }
  }

  function sendRequest() {
    authentication.authenticate(email, password, "default", remember);
  }

  function handleEmail(event) {
    setEmail(event.target.value);
  }

  function handlePassword(event) {
    setPassword(event.target.value);
  }

  function handleRemember(event) {
    setRemember(event.target.checked);
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({
      type: "FAILURE_DISMISS"
    });
    setOpenSnackbar(false);
  };

  let result = "";

  if (state.loading) {
    result = (
      <div className="sweet-loading" className={classes.screenCentered}>
        <CircleLoader
          size={75}
          color={theme.palette.primary.main}
          loading={state.loading}
        />
      </div>
    );
  } else {
    result = (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Connexion
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Adresse email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleEmail}
              onKeyPress={sendRequestEnter}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handlePassword}
              onKeyPress={sendRequestEnter}
            />
            {/* <FormControlLabel
            control={
              <Checkbox
                value="remember"
                onChange={handleRemember}
                color="primary"
              />
            }
            label="Se souvenir de moi"
          /> */}
            <Button
              type="button"
              onClick={sendRequest}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Se connecter
            </Button>
            {openSnackbar == true && (
              <SnackbarContentWrapper
                variant="error"
                className={classes.margin}
                message="Problème lors de la connexion"
                onClose={handleCloseSnackbar}
              />
            )}
            {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid> */}
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }

  return result;
}
