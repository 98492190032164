import React from "react";
import { useNumberCallData } from "api/NumberCallData";
import { Paper } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CountUp from "react-countup";
import moment from "moment";
import Title from "common/Title";
import { CircleLoader } from "react-spinners";

const useStyles = makeStyles(theme => ({
  blockNumber: {
    color: theme.palette.suezLogo.main,
    "font-size": "70px",
    "text-align": "center"
  },
  loader: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  }
}));

export default function NumberCallChart({ startDate, endDate, kiosks }) {
  const classes = useStyles();
  const theme = useTheme();

  const numberCall = useNumberCallData({
    from: moment(startDate).format("YYYY-MM-DD HH:mm"),
    to: moment(endDate).format("YYYY-MM-DD HH:mm"),
    kiosks
  });
  const number = numberCall.data;
  const durationParam = 2.75;
  let result = "";

  if (numberCall.loading) {
    result = (
      <div className="sweet-loading" className={classes.loader}>
        <CircleLoader
          size={150}
          color={theme.palette.primary.main}
          loading={numberCall.loading}
        />
      </div>
    );
  }
  if (numberCall.loading || !numberCall.data) {
    result = <div className={classes.blockNumber}>N/C</div>;
  } else {
    result = (
      <div className={classes.blockNumber}>
        <CountUp start={0} end={number} duration={durationParam} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Title>Nombre total d'appels</Title>
      {result}
    </React.Fragment>
  );
}
