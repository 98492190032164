import React, { useState, useEffect, useContext } from "react";
import AbpAppService from "common/AbpService";
import { AuthContext } from "contexts/AuthContext";

export function useKioskData() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const { state } = useContext(AuthContext);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const result = await KioskData();
      setData(result);
      setLoading(false);
    }

    loadData();
  }, []);

  async function KioskData() {
    const service = new AbpAppService("kiosk");

    const result = await service.invoke(
      "get",
      "GetAllKiosk",
      null,
      state.token
    );

    const kioskData = result.data.result;

    return kioskData;
  }

  return {
    data,
    loading
  };
}
