import React, { useState, useEffect } from "react";
import { useNumberCallByIntervalData } from "api/NumberCallByIntervalData";
import { Paper } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import CountUp from "react-countup";
import moment from "moment";
import Title from "common/Title";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { Input } from "@material-ui/core";
import { CircleLoader } from "react-spinners";

const useStyles = makeStyles(theme => ({
  blockNumber: {
    color: theme.palette.suezLogo.main,
    "font-size": "70px",
    "text-align": "center"
  },
  loader: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  }
}));

export default function NumberCallByIntervalChart({
  startDate,
  endDate,
  kiosks
}) {
  const classes = useStyles();
  const theme = useTheme();
  let result = "";
  let numberCallByInterval = useNumberCallByIntervalData({
    from: moment(startDate).format("YYYY-MM-DD HH:mm"),
    to: moment(endDate).format("YYYY-MM-DD HH:mm"),
    kiosks
  });

  let local = {
    format: "DD/MM/YYYY HH:mm",
    sundayFirst: false
  };

  let ranges = {
    "Aujourd'hui": [moment().startOf("day"), moment().endOf("day")],
    Hier: [
      moment()
        .subtract(1, "days")
        .startOf("day"),
      moment()
        .subtract(1, "days")
        .endOf("day")
    ],
    "3 derniers jours": [
      moment()
        .subtract(3, "days")
        .startOf("day"),
      moment().endOf("day")
    ]
  };
  let maxDate = moment(endDate).add(24, "hour");
  let disabled = false;
  let value = `${moment(startDate).format("DD/MM/YYYY HH:mm")} - ${moment(
    endDate
  ).format("DD/MM/YYYY HH:mm")}`;

  if (numberCallByInterval.loading) {
    result = (
      <div className="sweet-loading" className={classes.loader}>
        <CircleLoader
          size={150}
          color={theme.palette.primary.main}
          loading={numberCallByInterval.loading}
        />
      </div>
    );
  }
  if (!numberCallByInterval.data || numberCallByInterval.data.length == 0) {
    result = (
      <Title>
        <ErrorOutlineIcon color="primary" />
        Aucune données disponibles
      </Title>
    );
  } else {
    result = (
      <React.Fragment>
        <ResponsiveContainer>
          <LineChart
            width={600}
            height={300}
            data={numberCallByInterval.data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="label" />
            <YAxis />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="count"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Title>Nombre d'appels</Title>
      {result}
    </React.Fragment>
  );
}
